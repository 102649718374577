



































































import Vue from "vue";
import moment, { Moment } from "moment";
import { DATE_FORMAT_REPORT, DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { FormModel } from "ant-design-vue";
import { reportService } from "@service/report.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { downloadFile } from "@/helpers/file-reader";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
export default Vue.extend({
  name: "ReportHpp",
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        branchId: undefined as string | undefined,
        productId: undefined as string | undefined,
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        asOfDate: moment() as Moment | null,
      },
      rules: {
        branchId: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        productCode: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
        productName: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
      },
      loading: false,
    };
  },
  methods: {
    onSelectProduct(meta, source: "code" | "name"): void {
      this.form.productId = meta.meta?.id || null;
      if (source === "code") {
        this.form.productName = meta.meta?.name || undefined;
      } else {
        this.form.productCode = meta.meta?.code || undefined;
      }
    },
    validateForm(): void {
      const form = this.$refs.form as FormModel;
      form.validate((valid: boolean) => {
        if (valid) {
          this.handleDownload();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading = true;
        const searchBy: string[] = [];
        if (this.form.branchId) {
          searchBy.push(`branchId~${this.form.branchId}`);
        }
        if (this.form.productId) {
          searchBy.push(`productId~${this.form.productId}`);
        }

        const params: RequestQueryParamsModel = {
          search: searchBy.join("_AND_"),
        };
        const file = await reportService.downloadHpp(params);
        downloadFile(new Blob([file]), `report_hpp_${this.form.asOfDate?.format(DATE_FORMAT_REPORT)}.xlsx`);
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading = false;
      }
    },
    handleClear(): void {
      this.form = {
        branchId: undefined as string | undefined,
        productId: undefined as string | undefined,
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        asOfDate: moment() as Moment | null,
      };
    },
    handleBack(): void {
      this.$router.back();
    }
  }
});
